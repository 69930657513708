
import { Component, Vue } from 'vue-property-decorator';
import MenuTile from './MenuTile.vue';
import { VsOption } from '@/components/layouts/vs-alert';
import AuthResponse from '../../models/AuthResponse';

@Component({ components: { MenuTile } })
class MenuPage extends Vue {

    public avatarLoading: boolean = false;

    public get user(): AuthResponse | null {
        return this.$store.getters['getUser'];
    }

    public get userImage(){
        if(this.user != null) {
            return this.user?.imagem.startsWith('data:') ?
                this.user.imagem :
                `https://pilotar.blob.core.windows.net/pilotar/${this.user.imagem}`;
        }
        return require('@/modules/core/assets/img/home/avatar.jpg');
    }

    public logout(){
        const options: VsOption[] = [{
            title: 'Voltar',
            primary: true
        },
        {
            title: 'Sair',
            action: () => {
                this.$store.commit('logout');
            }
        }];

        this.$pilotarAlert('Sair', 'Tem certeza que deseja sair do pilotar?', options);
    }

    public async changeAvatar() {
        try {
            this.avatarLoading = true;
            await this.$store.dispatch('updateAvatar');
        }
        catch(error) {
            this.avatarLoading = false;
            if (error === "ManyFaces") {
                this.$pilotarAlert('Opss...', "Identificamos mais de uma pessoa na foto, você precisa estar sozinho. Tente novamente e certifique-se de que apenas você irá aparecer na foto.");
            }
            else if (error === "FaceNotFound" || error === "FaceNotMatch") {
                this.$pilotarAlert('Opss...', "Não conseguimos identificar seu rosto na foto. Experimente mudar de posição, buscar um lugar com mais iluminação e tente novamente.");
            }
        }
        finally{
            this.avatarLoading = false;
        }
    }
}

export default MenuPage;
