
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
class MenuTile extends Vue {
    @Prop({ type: String, default: '' })
    private readonly text!: string;

    @Prop({ type: String, default: '' })
    private readonly icon!: string;

}

export default MenuTile;
