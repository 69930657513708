
import { Component, Vue } from 'vue-property-decorator';
import AuthResponse from '@/models/AuthResponse';

@Component({ })
class SuaContaPage extends Vue {
    
    private loading: boolean = false;

    private get user(): AuthResponse {
        return this.$store.getters['getUser'];
    }
}

export default SuaContaPage;
