
import { Component, Vue } from 'vue-property-decorator';

@Component({})
class ConfiguracoesPage extends Vue {

    public soundApp = true;
    public graphic = 'padrao';
    public control: string = 'camera';
    public camera = 'deslizar';
    public direcaoMoto = 'setas';
    public direcaoCarro = 'volante';
    public sensibilidadeMoto = 50;
    public sensibilidadeCarro = 50;
}

export default ConfiguracoesPage;
