
import { Component, Vue } from 'vue-property-decorator';
import NativeService from '../../services/NativeService';

@Component({})
class InviteFriendsPage extends Vue {
    private share(){
        NativeService.Share();
    }
}

export default InviteFriendsPage;
