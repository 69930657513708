
import { Component, Vue } from 'vue-property-decorator';
import AuthResponse from '@/models/AuthResponse';
import UserService from '@/services/UserService';

@Component({ })
class ExcluirContaFinalPage extends Vue {
    
    private loading: boolean = false;
    private motivo: string = '';
    private comentario: string = '';

    private get user(): AuthResponse {
        return this.$store.getters['getUser'];
    }
  
    private async excluirConta () {
        this.loading = true;
        await UserService.ExcluirConta(this.motivo, this.comentario);

        this.$store.commit('logout');

        this.$pilotarAlert(
            "Conta excluída!",
            "",
            [
                {
                    title: "Ok",
                    action: async () => {
                        this.$store.commit('clearStack', { page: 'autoescolas', transition: 'crossFade' });
                    }
                }
            ],
        );

        this.loading = false;
    }

}

export default ExcluirContaFinalPage;
