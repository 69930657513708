
import { Component, Vue } from 'vue-property-decorator';

@Component({})
class RedesSociaisPage extends Vue {
    private navigate(url: string) {
        window.location.assign(url);
    }
}

export default RedesSociaisPage;
